// Fonts
$def_font: "FuturaPT", sans-serif;

// Colors
$c0: #111111;
$c1: #8BC34A;
$c2: #607D8B;
$c3: #828282;
$c5: #ffffff;

// Hamburger
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: #fff;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;

// Media queries breakpoints for mixins
$xsm: 375px;

// Small ≥576px
$sm: 576px;
$sm_maxw: 540px;

// Medium ≥768px
$md: 768px;
$md_maxw: 720px;

// Large 1024px
$lg: 1024px;
$lg_maxw: 1024px;

// Extra Large ≥1200px
$xl: 1200px;
$xl_maxw: 1140px;

// Extra Large ≥1440px
$xxl: 1440px;
$xxl_maxw: 1390px;

// Extra Large ≥1440px
$xxxl: 1600px;
$xxxl_maxw: 1560px;

// Vertical breakpoints
$h800: 800px;

//portfolio
$h730: 730px;

// ==========================================================================
// Bootstrap
// ==========================================================================
$grid-columns: 12;
$grid-gutter-width: 30px;
// Media queries breakpoints
$grid-breakpoints: (xs: 0, sm: $sm, md: $md, lg: $lg, xl: $xl);
// grid container width
$container-max-widths: (sm: $sm_maxw, md: $md_maxw, lg: $lg_maxw, xl: $xl_maxw);
